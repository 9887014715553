// import React, { useState } from "react";
// import LeftImage from "../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import wlogo from "../../assets/Images/White_TCL_logo.png";
// //  import GoogleLogin from "react-google-login";
// import "./login.css";

// function LogIn() {
//   const [formData, setFormData] = useState({
//     username: "",
//     password: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status
//   const [loading, setLoading] = useState(false); // New state for loading spinner
//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true); // Set loading state to true when submitting the form

//     // Check if username and password fields are filled
//     if (!formData.username || !formData.password) {
//       setLoading(false); // Set loading state to false if form fields are not filled
//       toast.error("Please fill in all fields");
//       return;
//     }

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       const responseData = await response.json();
//       const token = responseData.token;
//       if (response.ok) {
//         if (responseData.status === "true") {
//           // Login successful
//           // Redirect user to the home page
//           sessionStorage.setItem("token", token);
//           sessionStorage.setItem("isLoggedIn", true); // Set isLoggedIn to true in session storage
//           toast.success("Login successful! Redirecting...");
//           setTimeout(() => navigate("/"), 2000); // Redirect after a short delay
//           // setIsLoggedIn(true); // Set isLoggedIn to true
//           // navigate("/");
//         } else {
//           // Login failed
//           toast.error(responseData.message);
//         }
//       } else {
//         // Handle other error statuses
//         // console.error("Error logging in:", response.statusText);
//         toast.error("An error occurred while logging in.");
//       }
//     } catch (error) {
//       toast.error("An error occurred while logging in.");
//     } finally {
//       setLoading(false); // Set loading state to false after form submission or error handling
//       setFormData({ username: "", password: "" }); // Reset the form data
//     }
//   };
//   const trigger = () => {
//     console.log("triggers");
//     navigate("/"); // Redirect to the home page
//   };

//   return (
//     <div className="container-fluid">
//      <ToastContainer />
//       {/* Your JSX code */}
//       <div className="row justify-content-center m-0">
//         {/* Loading spinner */}
//         {loading && (
//           <div className="spinner-overlay">
//             <div className="spinner"></div>
//           </div>
//         )}
//         <div 
//         className="col-lg-5 p-0 d-none d-lg-block">
        
//           <div
//             className="w-100 h-100"
//             style={{
//               backgroundImage: `url(${LeftImage})`,
//               backgroundSize: "100% 100%",
//             }}
//           >
//             <div
//               className="ms-3"
//               style={{ cursor: "pointer", color: "#FFFFFF" }}
//               // onClick={() => { navigate("/")}
//               onClick={trigger}
//             >
//               <img
//                 src={wlogo}
//                 className="d-none d-md-block"
//                 width="185"
//                 alt=""
//               />
            
//             </div>

//             <div className="h-75 d-flex justify-content-center align-items-center">
//               <h4 style={{ color: "#FFFFFF" }}>
//                 <b>LADAKH</b>
//               </h4>
//             </div>
//           </div>
// <div
//   className="col-lg-7 col-12"
//   style={{ backgroundColor: "#03045E", height: "100vh" }}
// >
//   {/* Mobile logo with click handler */}
//   <img
//     src={wlogo}
//     className="d-block d-md-none"
//     width="120"
//     alt="Logo"
//     style={{ cursor: "pointer" }}
//     onClick={trigger}
//   />
// </div>


//         </div>
//         <div
//           className="col-lg-7 col-12"
//           style={{ backgroundColor: "#03045E", height: "100vh" }}
//         >
//           <img src={wlogo} className="d-block d-md-none" width="120" alt="" />
//           <div className="pa-2 h-100 d-flex align-items-center justify-content-center">
//             <div
//               className="card custom-card mx-2"
//               style={{ borderRadius: "15px" }}
//             >
//               <div className="card-body px-2 px-md-5 py-1 py-md-4 w-100">
//                 <h2 className="card-title mt-2" style={{ color: "#03045E" }}>
//                   LOGIN
//                 </h2>
//                 <p className="text-muted" style={{ fontSize: "medium" }}>
//                   Hey, Enter your details to get login to your account
//                 </p>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <input
//                       type="text"
//                       name="username"
//                       placeholder="Email ID"
//                       value={formData.username}
//                       onChange={handleChange}
//                       className="form-control"
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <div className="input-group">
//                       <input
//                         type={showPassword ? "text" : "password"}
//                         name="password"
//                         placeholder="Password"
//                         value={formData.password}
//                         onChange={handleChange}
//                         className="form-control"
//                         required
//                       />
//                       <button
//                         type="button"
//                         className="btn btn-outline-secondary"
//                         onClick={togglePasswordVisibility}
//                       >
//                         {showPassword ? "Hide" : "Show"}
//                       </button>
//                     </div>
//                     <div className="text-end mt-2">
//                       <Link
//                         to="/ForgetPassword"
//                         className="link-dark"
//                         style={{ textDecoration: "none" }}
//                       >
//                         Forget Password?
//                       </Link>
//                     </div>
//                   </div>
//                   <div className="text-center">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         borderRadius: "20px",
//                         backgroundColor: "#03045E",
//                         color: "white",
//                         textDecoration: "none",
//                       }}
//                     >
//                       Login
//                     </button>
//                   </div>


//                   <p className="text-center mt-3">
//                     Don't have an account?{" "}
//                     <Link
//                       to="/signup"
//                       style={{
//                         color: "#03045E",
//                         fontWeight: "bold",
//                         textDecoration: "none",
//                       }}
//                     >
//                       Sign Up
//                     </Link>
//                     <span> now</span>
//                   </p>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default LogIn;

import React, { useState } from "react";
import LeftImage from "../../assets/Images/a1a1.png";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import wlogo from "../../assets/Images/White_TCL_logo.png";
import "./login.css";

function LogIn() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.username || !formData.password) {
      setLoading(false);
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      if (response.ok) {
        if (responseData.status === "true") {
          sessionStorage.setItem("token", responseData.token);
          sessionStorage.setItem("isLoggedIn", true);
          toast.success("Login successful! Redirecting...");
          setTimeout(() => navigate("/"), 2000);
        } else {
          toast.error(responseData.message);
        }
      } else {
        toast.error("An error occurred while logging in.");
      }
    } catch (error) {
      toast.error("An error occurred while logging in.");
    } finally {
      setLoading(false);
      setFormData({ username: "", password: "" });
    }
  };

  const trigger = () => {
    navigate("/");
  };

  return (
    <div className="container-fluid" style={{ overflow: "hidden"}}>
      <ToastContainer />
      <div className="row justify-content-center m-0">
        {loading && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        )}
        <div className="col-lg-5 p-0 d-none d-lg-block">
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${LeftImage})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div
              className="ms-3"
              style={{ cursor: "pointer", color: "#FFFFFF" }}
              onClick={trigger}
            >
              <img
              className="d-none d-md-block "
                src={wlogo}
                width="185"
                alt="Logo"
              />
            </div>

            <div className="h-75 d-flex justify-content-center align-items-center">
              <h4 style={{ color: "#FFFFFF" }}>
                <b>LADAKH</b>
              </h4>
            </div>
          </div>
        </div>
        <div
          className="col-lg-7 col-12"
          style={{ backgroundColor: "#03045E", height: "100vh" }}
        >
          <img
            src={wlogo}
            className="d-block d-md-none mobile-logo"
            width="120"
            alt="Logo"
            style={{ cursor: "pointer" }}
            onClick={trigger}
          />
          <div className="pa-2 h-100 d-flex align-items-center justify-content-center">
            <div
              className="card custom-card mx-2"
              style={{ borderRadius: "15px" }}
            >
              <div className="card-body px-2 px-md-5 py-1 py-md-4 w-100">
                <h2 className="card-title mt-2" style={{ color: "#03045E" }}>
                  LOGIN
                </h2>
                <p className="text-muted" style={{ fontSize: "medium" }}>
                  Hey, Enter your details to get login to your account
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="username"
                      placeholder="Email ID"
                      value={formData.username}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    <div className="text-end mt-2">
                      <Link
                        to="/ForgetPassword"
                        className="link-dark"
                        style={{ textDecoration: "none" }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#03045E",
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      Login
                    </button>
                  </div>
                  <p className="text-center mt-3">
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      style={{
                        color: "#03045E",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Sign Up
                    </Link>
                    <span> now</span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
