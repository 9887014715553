import React, { useState, useEffect } from "react";
import pp1 from "../../assets/Images/pp1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const MyProfile = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [editableFields, setEditableFields] = useState({
    username: false,
    password:false
  });
  const token = sessionStorage.getItem("token");
  //console.log("session token - ", token);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
      const responseData = await response.json();
     

      if (response.ok) {
        setUserData(responseData.data);
      } else {
        console.error("Error fetching user profile data:", responseData);
        // Handle error
      }
    } catch (error) {
      console.error("Error fetching user profile data:", error);
      // Handle error
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // You can perform further actions with the selected file, such as uploading it to a server
  };

  const handleEditClick = (field) => {
    setEditableFields({ ...editableFields, [field]: true });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleUpdateClick = async () => {
    try {
      const formData = new FormData();
      formData.append("token", token);
      formData.append("Username", userData.name);
      formData.append("Email", userData.email);
      formData.append("PhoneNumber", userData.phone);
      formData.append("Password", userData.password);
      
      // Check if selectedFile is not null before appending
      if (selectedFile) {
        formData.append("Image", selectedFile);
      }
  
      console.log("Form Data:------", formData);
      
          //`${process.env.REACT_APP_API_URL}ProfileUpdate`
          const response = await fetch("https://api.travellerchoiceladakh.com/api/ProfileUpdate", {
            method: "POST",
            body: formData,
          });
      const responseData = await response.json();
        console.log("response data profileUpdate",responseData);
      if (response.ok) {
        // Update successful, reset editable fields
        setEditableFields({
          username: false,
          password: false,
        });
      } else {
        console.error("Error updating user profile:", responseData);
        // Handle error
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
      // Handle error
    }
  };
  
  

  return (
    <>
      <div className="row mt-0 mt-md-5 mx-3 me-md-0 ms-md-5">
        <div className="col-lg-12 d-flex justify-content-start">
          <div style={{ position: "relative" }}>
            <img src={pp1} alt="pp1" className="profile-image" />
            <label htmlFor="fileInput" className="camera-icon-wrapper">
              <FontAwesomeIcon icon={faCamera} className="camera-icon" />
            </label>
            <input
              type="file"
              id="fileInput"
              className="file-input"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="mt-5">
            <h2>Hello {userData ? userData.name : "Username"}!</h2>
            <p>Welcome back and explore the world.</p>
          </div>
        </div>

        <div className="col-lg-8">
          <form className="profile-form">

          <div className="form-group mb-3">
              <label htmlFor="username">Username</label>
              <div className="input-group">
                <input
                  type="text"
                  id="username"
                  name="name"
                  className="form-control"
                  placeholder="Enter your username"
                  value={userData ? userData.name : ""}
                  readOnly={!editableFields.username}
                  onChange={handleInputChange}
                />
                <div className="input-group-append">
                  {!editableFields.username ? (
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() => handleEditClick("username")}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() =>
                        setEditableFields({
                          ...editableFields,
                          username: false,
                        })
                      }
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="email">Email Id</label>
              <div className="input-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  value={userData ? userData.email : ""}
                  readOnly={!editableFields.email}
                  // onChange={handleInputChange}
                />
             
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="form-control"
                  placeholder="Enter your phone number"
                  value={userData ? userData.phone : ""}
                  readOnly={!editableFields.phone}
                  // onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group mb-3">
               <label htmlFor="password">Password</label>
               <div className="input-group">
             <input
                  type="text"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter your password"
                  readOnly={!editableFields.password}
                  onChange={handleInputChange}
                />
                 <div className="input-group-append">
                  {!editableFields.password ? (
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() => handleEditClick("password")}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() => setEditableFields({ ...editableFields, password: false })}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-update mt-3"
                style={{ color: "#FFFFFF" }}
                onClick={handleUpdateClick}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
