

// import React, { useState } from "react";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function ConfirmPassword() {
//   const [formData, setFormData] = useState({
//     createPassword: "",
//     confirmPassword: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [passwordsMatch, setPasswordsMatch] = useState(true);
//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Check if passwords match
//     if (formData.createPassword !== formData.confirmPassword) {
//       setPasswordsMatch(false);
//       return;
//     }
//     setPasswordsMatch(true);
//     try {
//       // Retrieve token from session storage
//       const token = sessionStorage.getItem("resetToken");
//       console.log("tol",token);
//       // Call backend API to change password
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}changePassword`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             password: formData.createPassword,
//             token: token, // Include token in the request body
//           }), // Send new password and token to the backend
//         }
//       );
//       console.log("token--",token);
//       console.log("response--",response);

//       if (response.ok) {
//         // Password change successful, show success message and navigate to login page
//         toast.success("Password changed", {
//           position: "top-center",
//           autoClose: 5000,
//           onClose: () => navigate("/login"),
//         });
//       } else {
//         // Password change failed, handle error
//         console.error("Password change failed");
//         // You can display an error message to the user
//       }
//     } catch (error) {
//       console.error("Error changing password:", error);
//       // Handle network errors or other exceptions
//     }
//   };


//   return (
//     <div className="container-fluid">
//       <div className="row justify-content-center">
//         <div className="col-lg-5 p-0 d-none d-lg-block">
//         <div
//             className="w-100 h-100"
//             style={{
//               backgroundImage: `url(${LeftImage})`,
//               objectFit:"cover",
//                filter: "blur(1px)",
//               // webkitBackdropFilter: "blur(10px)",
//             }}
//           >
//             <div className="ms-3">
//               <h3 style={{ color: "#FFFFFF" }}><b>trxvllrschoice</b></h3>
//             </div>
//             <div className="h-75 d-flex justify-content-center align-items-center">
//               <h4 style={{ color: "#FFFFFF" }}><b>LADAKH</b></h4>
//             </div>
//           </div>
//         </div>
//         <div
//           className="col-lg-7 "
//           style={{ backgroundColor: "#03045E", height: "100vh" }}
//         >
//           <div className="h-100 d-flex align-items-center justify-content-center">
//             <div
//               className="card mx-2 custom-card "
//               style={{ maxheight: "550px", borderRadius: "20px" }}
//             >
//               <div
//                 className="card-body p-5"
//                 style={{ height: "100%" }}
//               >
//                 <h2 className="card-title" style={{ color: "#03045E" }}>
//                   Create New Password
//                 </h2>
//                 <p className="text-muted" style={{ fontSize: "small" }}>
//                   Fill your password credentials
//                 </p>
//                 <form onSubmit={handleSubmit} >
//                   {/* New password fields */}
//                   <div className="mb-3">
//                     <input
//                       type="password"
//                       name="createPassword"
//                       placeholder="Create Password"
//                       value={formData.createPassword}
//                       onChange={handleChange}
//                       className="form-control"
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="password"
//                       name="confirmPassword"
//                       placeholder="Confirm Password"
//                       value={formData.confirmPassword}
//                       onChange={handleChange}
//                       className={`form-control ${
//                         !passwordsMatch ? "is-invalid" : ""
//                       }`}
//                       required
//                     />
//                     {!passwordsMatch && (
//                       <div className="invalid-feedback">
//                         Passwords do not match
//                       </div>
//                     )}
//                   </div>
//                   <div className="text-center">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         borderRadius: "20px",
//                         backgroundColor: "#03045E",
//                         color: "white",
//                       }}
//                     >
//                       Confirm
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ConfirmPassword;







// import React, { useState } from "react";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// // Define the spinner component
// const Spinner = () => (
//   <div className="loader-container">
//     <div className="loader"></div>
//   </div>
// );

// function ConfirmPassword() {
//   const [formData, setFormData] = useState({
//     createPassword: "",
//     confirmPassword: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [passwordsMatch, setPasswordsMatch] = useState(true);
//   const [loading, setLoading] = useState(false); // State for loading spinner
//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     setLoading(true); // Set loading state to true when submitting the form

// //     // Check if passwords match
// //     if (formData.createPassword !== formData.confirmPassword) {
// //       setPasswordsMatch(false);
// //       setLoading(false); // Reset loading state if passwords do not match
// //       return;
// //     }
// //     setPasswordsMatch(true);

// //     try {
// //       // Retrieve token from session storage
// //       const token = sessionStorage.getItem("resetToken");
// //        console.log("cp0->",token);
// //       // Call backend API to change password
// //       const response = await fetch(
// //         `${process.env.REACT_APP_API_URL}changePassword`,
// //         {
// //           method: "POST",
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //           body: JSON.stringify({
// //             password: formData.createPassword,
// //             token: token, // Include token in the request body
// //           }), // Send new password and token to the backend
// //         }
// //       );
// //  console.log("respose->",response); 
// //       if (response.ok) {
// //         // Password change successful, show success message and navigate to login page
// //         toast.success("Password changed", {
// //           position: "top-center",
// //           autoClose: 5000,
// //           onClose: () => navigate("/login"),
// //         });
// //       } else {
// //         // Password change failed, handle error
// //         console.error("Password change failed");
// //         // You can display an error message to the user
// //       }
// //     } catch (error) {
// //       console.error("Error changing password:", error);
// //       // Handle network errors or other exceptions
// //     } finally {
// //       setLoading(false); // Set loading state to false after form submission or error handling
// //     }
// //   };

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   setLoading(true); // Set loading state to true when submitting the form

//   // Check if passwords match
//   if (formData.createPassword !== formData.confirmPassword) {
//     setPasswordsMatch(false);
//     setLoading(false); // Reset loading state if passwords do not match
//     return;
//   }
//   setPasswordsMatch(true);

//   try {
//     // Retrieve token from session storage
//     const token = sessionStorage.getItem("resetToken");
//     console.log("Token Retrieved:", token);

//     // Call backend API to change password
//     const response = await fetch(
//       `${process.env.REACT_APP_API_URL}changePassword`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           password: formData.createPassword,
//           token: token, // Include token in the request body
//         }), // Send new password and token to the backend
//       }
//     );

//     // Parse the response data
//     const responseData = await response.json();
//     console.log("Response Body:", responseData);

//     if (responseData.status === 'true') {
//       // Password change successful, show success message and navigate to login page
//       toast.success("Password Successfully Changed!",{
//         position: "top-center",
//         autoClose: 5000,
//         onClose: () => navigate("/login"), // Navigate to login
//       });
//     } else {
//       // Password change failed, handle error
//       toast.error(responseData.message || "Password change failed!");
//     }
//   } catch (error) {
//     console.error("Error changing password:", error);
//     toast.error("Error occurred. Please try again.");
//   } finally {
//     setLoading(false); // Set loading state to false after form submission or error handling
//   }
// };



//   return (
//     <div className="container-fluid">
//       <div className="row justify-content-center">
//         <div className="col-lg-5 p-0 d-none d-lg-block">
//           <div
//             className="w-100 h-100"
//             style={{
//               backgroundImage: `url(${LeftImage})`,
//               backgroundSize: "cover", // Ensures the image covers the entire area
//               backgroundPosition: "center", // Centers the image
//               backgroundRepeat: "no-repeat", // Prevents tiling
//               objectFit: "cover",
//               filter: "blur(1px)",
//             }}
//           >
//             <div
//               className="ms-3"
//               style={{ cursor: "pointer", color: "#FFFFFF" }}
//               onClick={() => {
//                 window.location.href = "/";
//               }}
//             >
//               <h3>
//                 <b>trxvllrschoice</b>
//               </h3>
//             </div>
//             <div className="h-75 d-flex justify-content-center align-items-center">
//               <h4 style={{ color: "#FFFFFF" }}>
//                 <b>LADAKH</b>
//               </h4>
//             </div>
//           </div>
//         </div>
//         <div
//           className="col-lg-7 "
//           style={{ backgroundColor: "#03045E", height: "100vh" }}
//         >
//           <div className="h-100 d-flex align-items-center justify-content-center">
//             <div
//               className="card mx-2 custom-card "
//               style={{ maxHeight: "550px", borderRadius: "20px" }}
//             >
//               <div className="card-body p-5">
//                 <h2 className="card-title" style={{ color: "#03045E" }}>
//                   Create New Password
//                 </h2>
//                 <p className="text-muted" style={{ fontSize: "small" }}>
//                   Fill your password credentials
//                 </p>
//                 {loading && <Spinner />} {/* Render spinner if loading state is true */}
//                 <form onSubmit={handleSubmit}>
//                   {/* New password fields */}
//                   <div className="mb-3">
//                     <input
//                       type="password"
//                       name="createPassword"
//                       placeholder="Create Password"
//                       value={formData.createPassword}
//                       onChange={handleChange}
//                       className="form-control"
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="password"
//                       name="confirmPassword"
//                       placeholder="Confirm Password"
//                       value={formData.confirmPassword}
//                       onChange={handleChange}
//                       className={`form-control ${!passwordsMatch ? "is-invalid" : ""
//                         }`}
//                       required
//                     />
//                     {!passwordsMatch && (
//                       <div className="invalid-feedback">
//                         Passwords do not match
//                       </div>
//                     )}
//                   </div>
//                   <div className="text-center">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         borderRadius: "20px",
//                         backgroundColor: "#03045E",
//                         color: "white",
//                       }}
//                     >
//                       Confirm
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ConfirmPassword;

import React, { useState } from "react";
import LeftImage from "../../../assets/Images/a1a1.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Define the spinner component
const Spinner = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

function ConfirmPassword() {
  const [formData, setFormData] = useState({
    createPassword: "",
    confirmPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.createPassword !== formData.confirmPassword) {
      setPasswordsMatch(false);
      setLoading(false);
      return;
    }
    setPasswordsMatch(true);

    try {
      const token = sessionStorage.getItem("resetToken");
      console.log("Token Retrieved:", token);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: formData.createPassword,
            token: token,
          }),
        }
      );

      const responseData = await response.json();
      console.log("Response Body:", responseData);

      if (responseData.status === "true") {
        toast.success("Password Successfully Changed!", {
          position: "top-center",
          autoClose: 5000,
          onClose: () => navigate("/login"),
        });
      } else {
        toast.error(responseData.message || "Password change failed!");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error("Error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-5 p-0 d-none d-lg-block">
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${LeftImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              filter: "blur(1px)",
            }}
          >
            <div
              className="ms-3"
              style={{ cursor: "pointer", color: "#FFFFFF" }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <h3>
                <b>trxvllrschoice</b>
              </h3>
            </div>
            <div className="h-75 d-flex justify-content-center align-items-center">
              <h4 style={{ color: "#FFFFFF" }}>
                <b>LADAKH</b>
              </h4>
            </div>
          </div>
        </div>
        <div
          className="col-lg-7 "
          style={{ backgroundColor: "#03045E", height: "100vh" }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div
              className="card mx-2 custom-card "
              style={{ maxHeight: "550px", borderRadius: "20px" }}
            >
              <div className="card-body p-5">
                <h2 className="card-title" style={{ color: "#03045E" }}>
                  Create New Password
                </h2>
                <p className="text-muted" style={{ fontSize: "small" }}>
                  Fill your password credentials
                </p>
                {loading && <Spinner />}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="password"
                      name="createPassword"
                      placeholder="Create Password"
                      value={formData.createPassword}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className={`form-control ${
                        !passwordsMatch ? "is-invalid" : ""
                      }`}
                      required
                    />
                    {!passwordsMatch && (
                      <div className="invalid-feedback">
                        Passwords do not match
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#03045E",
                        color: "white",
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ConfirmPassword;
