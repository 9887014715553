import React from "react";
import t1 from "../../assets/Images/t1.svg";
import tick from "../../assets/Images/tickk.png";
import { Link } from "react-router-dom";

const TravLadhak = () => {
  return (
    <div
      className="mt-5"
      style={{
        backgroundImage: `url(${t1})`,
        backgroundSize: "cover",
      }}
    >
      <div className="container-fluid me-0 py-5">
        <div className="row align-items-center pe-0 mx-3">
          <div className="col-lg-7 d-flex align-items-center h-100 p-md-5 p-0">
            <div>
              <p className="h4 fw-bold" style={{ color: "#ff9900" }}>
                IT'S ALL ABOUT THE JOURNEY
              </p>
              <div style={{ color: "#FFFFFF", marginTop: "3rem" }}>
                {/* <h2>Travellers Choice Ladakh</h2> */}
                <h5>
                  Welcome to Travellers Choice Ladakh, where it's all about the
                  journey! Our tours are designed to give you an unforgettable
                  experience with breathtaking landscapes, unique culture, and
                  warm hospitality. We'll take you off the beaten path to
                  discover hidden gems and create memories that will last a
                  lifetime. Our local expertise and customized itineraries
                  ensure a safe and memorable visit. Join us, and let us show
                  you the beauty of Leh Ladakh!
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-5 h-100">
            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <div className="contact-box">
                {/* Use the FontAwesomeIcon component with the imported icon */}
                <h3 style={{ color: "#FFFFFF" }}>
                  <img src={tick} alt="arrow" />{" "}
                  <span style={{ marginLeft: "2rem" }}>Contact Now</span>
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravLadhak;
