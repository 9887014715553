// import React, { useState, useEffect } from "react";
// import Nav from "./Nav";
// import LadakhImg from "../../assets/Images/asdasdasd.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import Footer from "./Footer";
// import { useNavigate , Link} from "react-router-dom";

// // Import images
// import Biking from "../../assets/Images/biking.svg";
// import Culture from "../../assets/Images/cultural.svg";
// import Adventure from "../../assets/Images/adventure.svg";
// import Wildlife from "../../assets/Images/wildlife.svg";
// import TrekkingImage from "../../assets/Images/trekking.svg";
// import RaftingImage from "../../assets/Images/rafting.svg";

// const TripPackage = () => {
//   const [selectedData, setSelectedData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [allData, setAllData] = useState([]);
//   const [categoryHeading, setCategoryHeading] = useState("");

//   const navigate = useNavigate();
//   const tripPackageId = sessionStorage.getItem('tripPackageId');

//   const handleViewDetailClick = (slug) => {
//     console.log("Trip Package ID clicked:", slug);
//     sessionStorage.setItem("slugg", slug);
//     window.location.href = `/PackageDestination/${slug}`;
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}AllPackages`
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();
//         // console.log("Category Heading: ", data.data.category.heading);

//         setAllData(data.data);

//         const selectedImageData = data.data.filter(
//           (item) => item.category_id === parseInt(tripPackageId) || item.category_id === 1
//         );

//         const categoryHeading = selectedImageData.length > 0
//           ? selectedImageData[0].category.heading
//           : "";
//         setCategoryHeading(categoryHeading);
//         setSelectedData(selectedImageData);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [tripPackageId]);

//   const handleBook = (dataItem) => {
//     console.log("Trip clicked--:", dataItem.id);
//     sessionStorage.setItem("selectedId", dataItem.id);
//     sessionStorage.setItem("slugg", dataItem.slug);
//     const parsedId = parseInt(tripPackageId);

//     if (parsedId === 2) {
//       window.location.href = "/BookOnlineCarHotel";
//     } else {
//       window.location.href = "/BookOnline";
//     }
//   };

//   const filterIconMapping = {
//     Trekking: TrekkingImage,
//     Rafting: RaftingImage,
//     Cultural: Culture,
//     Adventure: Adventure,
//     Wildlife: Wildlife,
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="col-lg-12 px-0 position-relative">
//           <Nav />
//           <div
//             className="image-container"
//             style={{
//               width: "100%",
//               height: "65vh",
//               overflow: "hidden",
//               border: "1px solid #ddd",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               position: "relative",
//             }}
//           >
//             <img
//               src={LadakhImg}
//               alt="ladakhDestinations"
//               className="img-fluid"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//               }}
//             />
//             <div
//               className="overlay-text"
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 color: "white",
//                 textAlign: "center",
//                 opacity: 0.9,
//               }}
//             >
//               <h1 style={{ fontSize: "3rem", color: "Black" }}>
//                 <b>{categoryHeading}</b>
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container">
//         <div className="py-5 text-center" style={{ color: "#080B13" }}>
//           <h6 style={{ lineHeight: "28px" }}>
//             The most appropriate time to visit Ladakh is during the summer, from
//             June to September. This time is probably the most attractive weather
//             as the clear skies, sunny days, and temperatures between 20°C and
//             30°C allow you to enjoy an outdoor activity. They are linked to the
//             city center and, therefore, are eligible for many sports activities
//             like trekking, biking, and sightseeing. The summer season is also
//             when green landscapes take over the yards, leaving you craving more.
//             The blooming spring (March to May) and autumn (September to October)
//             seasons have moderate weather and fewer crowds, and they also have
//             their distinct charm. Still, summer is the best-suited time as it
//             has the ideal mix of ease of accessibility, weather, and cultural
//             events and carries all those beautiful wild landscapes and the most
//             prosperous cultural heritage.
//           </h6>
//         </div>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {selectedData && selectedData.length > 0 && (
//         <div className="container">
//           <div className="row justify-content-center">
//             {selectedData.map((dataItem, dataIndex) => {
//               if (
//                 !dataItem ||
//                 !dataItem.category ||
//                 !dataItem.image ||
//                 !dataItem.FilterSearch
//               )
//                 return null; // Check for null/undefined values
//               return (
//                 <div key={dataIndex} className="col-lg-8 mb-5">
//                   <div
//                     className="card border-0"
//                     style={{
//                       width: "100%",
//                       boxShadow: "0 0 30px 0 rgb(6 30 98 / 8%)",
//                     }}
//                   >
//                     <div className="row no-gutters">
//                       <div className="col-md-5 d-flex justify-content-center align-items-center">
//                         <img
//                           className="w-100 object-fit-cover lazy"
//                           src={`${dataItem.image[0]}`}
//                           alt={dataItem.name}
//                           style={{
//                             borderRadius: "10px",
//                             height: "250px",
//                           }}
//                         />
//                       </div>
//                       <div className="col-md-7 d-flex justify-content-center align-items-center">
//                         <div className="card-body">
//                           <div className="d-flex">
//                             <div>
//                               <h6
//                                 className="card-title"
//                                 style={{ color: "#023E8A" }}
//                               >
//                                 {" "}
//                                 <FontAwesomeIcon icon={faCalendar} />{" "}
//                                 {dataItem.days} Days
//                               </h6>
//                             </div>

//                             <div
//                               className="d-flex justify-content-end align-items-start"
//                               style={{
//                                 position: "absolute",
//                                 top: "10px",
//                                 right: "10px",
//                               }}
//                             >
//                               {dataItem.FilterSearch.map((filter) => (
//                                 <img
//                                   key={filter}
//                                   src={filterIconMapping[filter]}
//                                   alt={filter}
//                                   width="30"
//                                   height="30"
//                                   style={{ borderRadius: "50%" }}
//                                 />
//                               ))}
//                             </div>
//                           </div>

//                           <hr className="my-2" />
//                           <h5 className="card-text">
//                             <b>{dataItem.name}</b>
//                           </h5>
//                           <p
//                             className="card-text"
//                             style={{ color: "#666666", fontSize: "14px" }}
//                           >
//                             <FontAwesomeIcon
//                               icon={faLocationDot}
//                               className="me-2"
//                             />
//                             {dataItem.location}
//                           </p>
//                           {dataItem.category_id === 2 && (
//                             <small className="text-danger">
//                               **minimum 4 persons required**
//                             </small>
//                           )}
//                           <p className="text-end">
//                             <b>from Rs. {dataItem.price}/adult</b>
//                           </p>
//                           <div className="d-flex justify-content-end gap-4">
//                             <Link
//                               //to={`/PackageDestination/${dataItem.slug}`}
//                               onClick={() => handleViewDetailClick(dataItem.slug)}
//                               className="btn btn-outline-primary"
//                               style={{
//                                 borderRadius: "8px",
//                               }}
//                             >
//                               View Details
//                               <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 width="16"
//                                 height="16"
//                                 fill="currentColor"
//                                 className="bi bi-arrow-right ms-2"
//                                 viewBox="0 0 16 16"
//                               >
//                                 <path
//                                   fillRule="evenodd"
//                                   d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
//                                 />
//                               </svg>{" "}
//                             </Link>

//                             <Link
//                               // to="/BookOnline"
//                               onClick={() => handleBook(dataItem)}
//                               className="btn btn-primary"
//                             >
//                               Book Online
//                             </Link>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       )}
//       <Footer />
//     </>
//   );
// };

// export default TripPackage;


// PREVIOUSLY USED CODE-------------------->




//NEW CODE WITH SLUG MATCHING---------------->

// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate, Link } from "react-router-dom";
// import Nav from "./Nav";
// import LadakhImg from "../../assets/Images/asdasdasd.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import Footer from "./Footer";

// // Import images
// import Biking from "../../assets/Images/biking.svg";
// import Culture from "../../assets/Images/cultural.svg";
// import Adventure from "../../assets/Images/adventure.svg";
// import Wildlife from "../../assets/Images/wildlife.svg";
// import TrekkingImage from "../../assets/Images/trekking.svg";
// import RaftingImage from "../../assets/Images/rafting.svg";

// const TripPackage = () => {
//   const [selectedData, setSelectedData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [categoryHeading, setCategoryHeading] = useState("");
//   const [allData, setAllData] = useState([]);

//   const navigate = useNavigate();
//   const { name } = useParams(); // Get the 'name' from the URL

//   const handleViewDetailClick = (slug) => {
//     console.log("Trip Package ID clicked:", slug);
//     sessionStorage.setItem("slugg", slug);
//     // window.location.href = `/PackageDestination/${slug}`;
//     window.location.href = `/${slug}`;
//   };

//   const handleBook = (dataItem) => {
//     console.log("Trip clicked--:", dataItem.id);
//     sessionStorage.setItem("selectedId", dataItem.id);
//     sessionStorage.setItem("slugg", dataItem.slug);

//     const parsedId = parseInt(dataItem.category_id);

//     if (parsedId === 2) {
//       window.location.href = "/BookOnlineCarHotel";
//     } else {
//       window.location.href = "/BookOnline";
//     }
//   };

//   const filterIconMapping = {
//     Trekking: TrekkingImage,
//     Rafting: RaftingImage,
//     Cultural: Culture,
//     Adventure: Adventure,
//     Wildlife: Wildlife,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}AllPackages`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();

//         console.log("Fetched Data: ", data.data);
//         console.log("Slug from URL: ", name);

//         // Filter the data to find all items matching the category name or slug
//         const categoryNameToMatch = name; // Desired category name
//         const filteredData = data.data.filter((item) =>
//           item.slug === name || item.category.name === categoryNameToMatch
//         );

//         console.log("Filtered Data: --", filteredData);

//         // Set category heading and filtered data
//         if (filteredData.length > 0) {
//           const selectedCategoryHeading = filteredData[0].category.heading;
//           console.log("Category Heading: ", selectedCategoryHeading);
//           setCategoryHeading(selectedCategoryHeading);
//         } else {
//           setCategoryHeading("No matching category found");
//         }

//         setSelectedData(filteredData);
//         setAllData(data.data);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [name]);

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="col-lg-12 px-0 position-relative">
//           <Nav />
//           <div
//             className="image-container"
//             style={{
//               width: "100%",
//               height: "65vh",
//               overflow: "hidden",
//               border: "1px solid #ddd",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               position: "relative",
//             }}
//           >
//             <img
//               src={LadakhImg}
//               alt="ladakhDestinations"
//               className="img-fluid"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//               }}
//             />
//             <div
//               className="overlay-text"
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 color: "white",
//                 textAlign: "center",
//                 opacity: 0.9,
//               }}
//             >
//               <h1 style={{ fontSize: "3rem", color: "Black" }}>
//                 <b>{categoryHeading}</b>
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container">
//         <div className="py-5 text-center" style={{ color: "#080B13" }}>
//           <h6 style={{ lineHeight: "28px" }}>
//             The most appropriate time to visit Ladakh is during the summer, from
//             June to September. This time is probably the most attractive weather
//             as the clear skies, sunny days, and temperatures between 20°C and
//             30°C allow you to enjoy an outdoor activity. They are linked to the
//             city center and, therefore, are eligible for many sports activities
//             like trekking, biking, and sightseeing. The summer season is also
//             when green landscapes take over the yards, leaving you craving more.
//             The blooming spring (March to May) and autumn (September to October)
//             seasons have moderate weather and fewer crowds, and they also have
//             their distinct charm. Still, summer is the best-suited time as it
//             has the ideal mix of ease of accessibility, weather, and cultural
//             events and carries all those beautiful wild landscapes and the most
//             prosperous cultural heritage.
//           </h6>
//         </div>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {selectedData && selectedData.length > 0 ? (
//         <div className="container">
//           <div className="row justify-content-center">
//             {selectedData.map((dataItem, dataIndex) => (
//               <div key={dataIndex} className="col-lg-8 mb-5">
//                 <div
//                   className="card border-0"
//                   style={{
//                     width: "100%",
//                     boxShadow: "0 0 30px 0 rgb(6 30 98 / 8%)",
//                   }}
//                 >
//                   <div className="row no-gutters">
//                     <div className="col-md-5 d-flex justify-content-center align-items-center">
//                       <img
//                         className="w-100 object-fit-cover lazy"
//                         src={`${dataItem.image[0]}`}
//                         alt={dataItem.name}
//                         style={{
//                           borderRadius: "10px",
//                           height: "250px",
//                         }}
//                       />
//                     </div>
//                     <div className="col-md-7 d-flex justify-content-center align-items-center">
//                       <div className="card-body">
//                         <div className="d-flex">
//                           <div>
//                             <h6 className="card-title" style={{ color: "#023E8A" }}>
//                               <FontAwesomeIcon icon={faCalendar} /> {dataItem.days} Days
//                             </h6>
//                           </div>

//                           <div
//                             className="d-flex justify-content-end align-items-start"
//                             style={{
//                               position: "absolute",
//                               top: "10px",
//                               right: "10px",
//                             }}
//                           >
//                             {dataItem.FilterSearch.map((filter) => (
//                               <img
//                                 key={filter}
//                                 src={filterIconMapping[filter]}
//                                 alt={filter}
//                                 width="30"
//                                 height="30"
//                                 style={{ borderRadius: "50%" }}
//                               />
//                             ))}
//                           </div>
//                         </div>

//                         <hr className="my-2" />
//                         <h5 className="card-text">
//                           <b>{dataItem.name}</b>
//                         </h5>
//                         <p className="card-text" style={{ color: "#666666", fontSize: "14px" }}>
//                           <FontAwesomeIcon icon={faLocationDot} className="me-2" />
//                           {dataItem.location}
//                         </p>
//                         {dataItem.category_id === 2 && (
//                           <small className="text-danger">
//                             **minimum 4 persons required**
//                           </small>
//                         )}
//                         <p className="text-end">
//                           <b>from Rs. {dataItem.price}/adult</b>
//                         </p>
//                         <div className="d-flex justify-content-end gap-4">
//                           <Link
//                             onClick={() => handleViewDetailClick(dataItem.slug)}
//                             className="btn btn-outline-primary"
//                             style={{
//                               borderRadius: "20px",
//                               padding: "5px 20px",
//                             }}
//                           >
//                             View Details
//                           </Link>
//                           <Link
//                             onClick={() => handleBook(dataItem)}
//                             className="btn btn-primary"
//                             style={{
//                               borderRadius: "20px",
//                               padding: "5px 20px",
//                               backgroundColor: "#023E8A",
//                             }}
//                           >
//                             Book Now
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       ) : (
//         <p>No matching data found</p>
//       )}
//       <Footer />
//     </>
//   );
// };

// export default TripPackage;

// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate, Link } from "react-router-dom";
// import Nav from "./Nav";
// import LadakhImg from "../../assets/Images/asdasdasd.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import Footer from "./Footer";

// // Import images
// import Biking from "../../assets/Images/biking.svg";
// import Culture from "../../assets/Images/cultural.svg";
// import Adventure from "../../assets/Images/adventure.svg";
// import Wildlife from "../../assets/Images/wildlife.svg";
// import TrekkingImage from "../../assets/Images/trekking.svg";
// import RaftingImage from "../../assets/Images/rafting.svg";

// const TripPackage = () => {
//   const [selectedData, setSelectedData] = useState([]); // Ensure default to an empty array
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [categoryHeading, setCategoryHeading] = useState("");
//   const [allData, setAllData] = useState([]);

//   const navigate = useNavigate();
//   const { name } = useParams(); // Get the 'name' from the URL

//   const handleViewDetailClick = (slug) => {
//     console.log("Trip Package ID clicked:", slug);
//     sessionStorage.setItem("slugg", slug);
//     window.location.href = `/${slug}`;
//   };

//   const handleBook = (dataItem) => {
//     console.log("Trip clicked--:", dataItem.id);
//     sessionStorage.setItem("selectedId", dataItem.id);
//     sessionStorage.setItem("slugg", dataItem.slug);

//     const parsedId = parseInt(dataItem.category_id);

//     if (parsedId === 2) {
//       window.location.href = "/BookOnlineCarHotel";
//     } else {
//       window.location.href = "/BookOnline";
//     }
//   };

//   const filterIconMapping = {
//     Trekking: TrekkingImage,
//     Rafting: RaftingImage,
//     Cultural: Culture,
//     Adventure: Adventure,
//     Wildlife: Wildlife,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}AllPackages`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
//         const data = await response.json();

//         console.log("Fetched Data: ", data.data);
//         console.log("Slug from URL: ", name);

//         // Filter the data to find all items matching the category name or slug
//         const categoryNameToMatch = name; // Desired category name
//         const filteredData = data.data.filter((item) =>
//           item.slug === name || item.category.name === categoryNameToMatch
//         );

//         console.log("Filtered Data: --", filteredData);

//         // Set category heading and filtered data
//         if (filteredData.length > 0) {
//           const selectedCategoryHeading = filteredData[0].category.heading;
//           console.log("Category Heading: ", selectedCategoryHeading);
//           setCategoryHeading(selectedCategoryHeading);
//         } else {
//           setCategoryHeading("No matching category found");
//         }

//         setSelectedData(filteredData);
//         setAllData(data.data);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [name]);

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="col-lg-12 px-0 position-relative">
//           <Nav />
//           <div
//             className="image-container"
//             style={{
//               width: "100%",
//               height: "65vh",
//               overflow: "hidden",
//               border: "1px solid #ddd",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               position: "relative",
//             }}
//           >
//             <img
//               src={LadakhImg}
//               alt="ladakhDestinations"
//               className="img-fluid"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//               }}
//             />
//             <div
//               className="overlay-text"
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 color: "white",
//                 textAlign: "center",
//                 opacity: 0.9,
//               }}
//             >
//               <h1 style={{ fontSize: "3rem", color: "Black" }}>
//                 <b>{categoryHeading}</b>
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container">
//         <div className="py-5 text-center" style={{ color: "#080B13" }}>
//           <h6 style={{ lineHeight: "28px" }}>
//             The most appropriate time to visit Ladakh is during the summer, from
//             June to September...
//           </h6>
//         </div>
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       {Array.isArray(selectedData) && selectedData.length > 0 ? (
//         <div className="container">
//           <div className="row justify-content-center">
//             {selectedData.map((dataItem, dataIndex) => (
//               <div key={dataIndex} className="col-lg-8 mb-5">
//                 <div
//                   className="card border-0"
//                   style={{
//                     width: "100%",
//                     boxShadow: "0 0 30px 0 rgb(6 30 98 / 8%)",
//                   }}
//                 >
//                   <div className="row no-gutters">
//                     <div className="col-md-5 d-flex justify-content-center align-items-center">
//                       <img
//                         className="w-100 object-fit-cover lazy"
//                         src={`${dataItem.image[0]}`}
//                         alt={dataItem.name}
//                         style={{
//                           borderRadius: "10px",
//                           height: "250px",
//                         }}
//                       />
//                     </div>
//                     <div className="col-md-7 d-flex justify-content-center align-items-center">
//                       <div className="card-body">
//                         <div className="d-flex">
//                           <div>
//                             <h6 className="card-title" style={{ color: "#023E8A" }}>
//                               <FontAwesomeIcon icon={faCalendar} /> {dataItem.days} Days
//                             </h6>
//                           </div>

//                           <div
//                             className="d-flex justify-content-end align-items-start"
//                             style={{
//                               position: "absolute",
//                               top: "10px",
//                               right: "10px",
//                             }}
//                           >
//                             {dataItem.FilterSearch.map((filter) => (
//                               <img
//                                 key={filter}
//                                 src={filterIconMapping[filter]}
//                                 alt={filter}
//                                 width="30"
//                                 height="30"
//                                 style={{ borderRadius: "50%" }}
//                               />
//                             ))}
//                           </div>
//                         </div>

//                         <hr className="my-2" />
//                         <h5 className="card-text">
//                           <b>{dataItem.name}</b>
//                         </h5>
//                         <p className="card-text" style={{ color: "#666666", fontSize: "14px" }}>
//                           <FontAwesomeIcon icon={faLocationDot} className="me-2" />
//                           {dataItem.location}
//                         </p>
//                         {dataItem.category_id === 2 && (
//                           <small className="text-danger">
//                             **minimum 4 persons required**
//                           </small>
//                         )}
//                         <p className="text-end">
//                           <b>from Rs. {dataItem.price}/adult</b>
//                         </p>
//                         <div className="d-flex justify-content-end gap-4">
//                           <Link
//                             onClick={() => handleViewDetailClick(dataItem.slug)}
//                             className="btn btn-outline-primary"
//                             style={{
//                               borderRadius: "20px",
//                               padding: "5px 20px",
//                             }}
//                           >
//                             View Details
//                           </Link>
//                           <Link
//                             onClick={() => handleBook(dataItem)}
//                             className="btn btn-primary"
//                             style={{
//                               borderRadius: "20px",
//                               padding: "5px 20px",
//                               backgroundColor: "#023E8A",
//                             }}
//                           >
//                             Book Now
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       ) : (
//         <div className="container">
//           <p>No trip packages found for the selected category.</p>
//         </div>
//       )}
//       <Footer />
//     </>
//   );
// };

// export default TripPackage;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Nav from "./Nav";
import LadakhImg from "../../assets/Images/asdasdasd.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

// Import images
import Biking from "../../assets/Images/biking.svg";
import Culture from "../../assets/Images/cultural.svg";
import Adventure from "../../assets/Images/adventure.svg";
import Wildlife from "../../assets/Images/wildlife.svg";
import TrekkingImage from "../../assets/Images/trekking.svg";
import RaftingImage from "../../assets/Images/rafting.svg";

const TripPackage = () => {
  const [selectedData, setSelectedData] = useState([]); // Default to an empty array
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryHeading, setCategoryHeading] = useState("");
  const [allData, setAllData] = useState([]);

  const navigate = useNavigate();
  const { name } = useParams(); // Get the 'name' from the URL

  const handleViewDetailClick = (slug) => {
    sessionStorage.setItem("slugg", slug);
    window.location.href = `/${slug}`;
  };

  const handleBook = (dataItem) => {
    sessionStorage.setItem("selectedId", dataItem.id);
    sessionStorage.setItem("slugg", dataItem.slug);

    const parsedId = parseInt(dataItem.category_id);

    if (parsedId === 2) {
      window.location.href = "/BookOnlineCarHotel";
    } else {
      window.location.href = "/BookOnline";
    }
  };

  const filterIconMapping = {
    Trekking: TrekkingImage,
    Rafting: RaftingImage,
    Cultural: Culture,
    Adventure: Adventure,
    Wildlife: Wildlife,
    Biking:Biking
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}AllPackages`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        console.log("Fetched Data: ", data.data);
        console.log("Slug from URL: ", name);

        // Filter the data to find all items matching the category name or slug
        const categoryNameToMatch = name;
        const filteredData = Array.isArray(data.data)
          ? data.data.filter(
            (item) => item.slug === name || item.category?.name === categoryNameToMatch
          )
          : [];

        console.log("Filtered Data: --", filteredData);

        // Set category heading and filtered data
        if (filteredData.length > 0) {
          const selectedCategoryHeading = filteredData[0]?.category?.heading || "Category";
          setCategoryHeading(selectedCategoryHeading);
        } else {
          setCategoryHeading("No matching category found");
        }

        setSelectedData(filteredData);
        setAllData(Array.isArray(data.data) ? data.data : []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12 px-0 position-relative">
          <Nav />
          <div
            className="image-container"
            style={{
              width: "100%",
              height: "65vh",
              overflow: "hidden",
              border: "1px solid #ddd",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <img
              src={LadakhImg}
              alt="ladakhDestinations"
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              className="overlay-text"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                textAlign: "center",
                opacity: 0.9,
              }}
            >
              <h1 style={{ fontSize: "3rem", color: "Black" }}>
                <b>{categoryHeading}</b>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="py-5 text-center" style={{ color: "#080B13" }}>
          <h6 style={{ lineHeight: "28px" }}>
            The most appropriate time to visit Ladakh is during the summer, from
            June to September. This time is probably the most attractive weather
            as the clear skies, sunny days, and temperatures between 20°C and
            30°C allow you to enjoy an outdoor activity. They are linked to the
            city center and, therefore, are eligible for many sports activities
            like trekking, biking, and sightseeing. The summer season is also
            when green landscapes take over the yards, leaving you craving more.
            The blooming spring (March to May) and autumn (September to October)
            seasons have moderate weather and fewer crowds, and they also have
            their distinct charm. Still, summer is the best-suited time as it
            has the ideal mix of ease of accessibility, weather, and cultural
            events and carries all those beautiful wild landscapes and the most
            prosperous cultural heritage.
          </h6>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {Array.isArray(selectedData) && selectedData.length > 0 ? (
        <div className="container">
          <div className="row justify-content-center">
            {selectedData.map((dataItem, dataIndex) => (
              <div key={dataIndex} className="col-lg-8 mb-5">
                <div
                  className="card border-0"
                  style={{
                    width: "100%",
                    boxShadow: "0 0 30px 0 rgb(6 30 98 / 8%)",
                  }}
                >
                  <div className="row no-gutters">
                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                      <img
                        className="w-100 object-fit-cover lazy"
                        src={dataItem.image?.[0] || ""}
                        alt={dataItem.name}
                        style={{
                          borderRadius: "10px",
                          height: "250px",
                        }}
                      />
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center">
                      <div className="card-body">
                        <div className="d-flex">
                          <div>
                            <h6 className="card-title" style={{ color: "#023E8A" }}>
                              <FontAwesomeIcon icon={faCalendar} /> {dataItem.days || 0} Days
                            </h6>
                          </div>

                          <div
                            className="d-flex justify-content-end align-items-start"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            {(dataItem.FilterSearch || []).map((filter) => (
                              <img
                                key={filter}
                                src={filterIconMapping[filter]}
                                alt={filter}
                                width="30"
                                height="30"
                                style={{ borderRadius: "50%" }}
                              />
                            ))}
                          </div>
                        </div>

                        <hr className="my-2" />
                        <h5 className="card-text">
                          <b>{dataItem.name}</b>
                        </h5>
                        <p className="card-text" style={{ color: "#666666", fontSize: "14px" }}>
                          <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                          {dataItem.location}
                        </p>
                        {dataItem.category_id === 2 && (
                          <small className="text-danger">
                            **minimum 4 persons required**
                          </small>
                        )}
                        <p className="text-end">
                          <b>from Rs. {dataItem.price}/adult</b>
                        </p>
                        <div className="d-flex justify-content-end gap-4">
                          <Link
                            onClick={() => handleViewDetailClick(dataItem.slug)}
                            className="btn btn-outline-primary"
                            style={{
                              borderRadius: "20px",
                              padding: "5px 20px",
                            }}
                          >
                            View Details
                          </Link>
                          {/* <Link
                            onClick={() => handleBook(dataItem)}
                            className="btn btn-primary"
                            style={{
                              borderRadius: "20px",
                              padding: "5px 20px",
                              backgroundColor: "#023E8A",
                            }}
                          >
                            Book Now
                          </Link> */}
                          <a
                            href="tel:9149516495"
                            className="btn btn-primary"
                          >

                            Book
                            <i className="bi bi-telephone ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <p>No trip packages found for the selected category.</p>
        </div>
      )}
      <Footer />
    </>
  );
};

export default TripPackage;
